body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div .MuiMenu-paper {
  max-height: 23.5rem;
}

.RaLayout-content {
  padding: 8px !important;
}

.MuiPaper-root .MuiAutocomplete-listbox {
  width: 100% !important;
  min-width: 500px !important;
  overflow-x: auto !important;
}

#organizationId-listbox {
  min-width: 100px !important;
}

#systemId-listbox {
  min-width: 100px !important;
}

.priceProductInput input{
  direction: ltr;
  display: inline-block;
  text-align: right;
}

